<template>
  <div class="my-14 max-w-lg mx-auto">
    <div>
      <div class="flex items-start">
        <h3 class="text-2xl">
          Valor
        </h3>

        <div class="ml-auto flex items-end flex-col">
          <div v-if="params.installments > 1" class=" text-xl font-semibold">
            {{ params.installments }}x de <span>{{ formatCurrency(currentPrice.monthly) }}</span>
          </div>
          <div v-else class=" text-xl font-semibold">
            {{ params.installments }}x de <span>{{ formatCurrency(currentPrice.total * 0.95) }}</span>
          </div>

          <div v-if="params.installments > 1" class="text-sm ml-auto">
            Total de {{ formatCurrency(currentPrice.total) }}
          </div>
          <div v-else class="text-sm ml-auto">
            5% de desconto à vista
          </div>
        </div>
      </div>

      <div class="flex items-center">
        <span>
          1 ano de acesso
        </span>

        <CheckoutPaymentCoupon />
      </div>

      <hr class=" border-b-[1px] border-accent/20 my-4">
    </div>

    <h2 class="mb-2">
      Método de pagamento
    </h2>
    <VTabs
      v-model="params.paymentType"
      color="primary"
      grow
      class="border-[1px] border-accent/50 rounded-xl"
      selected-class="bg-primary text-white transition-all"
    >
      <VTab
        value="creditCard"
        class="w-1/2 inline-flex"
      >
        <img
          class="w-4 h-4 mr-1"
          src="/images/icons/creditcard.svg"
        >
        <b>Cartão</b>
      </VTab>
      <VTab
        value="pix"
        class="w-1/2"
      >
        <img
          class="w-4 h-4 mr-1"
          src="/images/icons/pix.svg"
        >
        <b>Pix</b>
      </VTab>
    </VTabs>

    <VTabsWindow
      v-model="params.paymentType"
      class=""
    >
      <VTabsWindowItem value="creditCard">
        <CheckoutPaymentCreditCardForm />
      </VTabsWindowItem>
      <VTabsWindowItem value="pix">
        <CheckoutPaymentPixForm />
      </VTabsWindowItem>
    </VTabsWindow>

    <div class="flex justify-center items-center mt-4 gap-2 text-center text-gray-500 text-sm">
      <VIcon icon="mdi-lock-check" />
      Pagamento seguro e criptografado.
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCheckout } from '../../composables/useCheckout'
import { CheckoutPaymentCreditCardForm, CheckoutPaymentPixForm, CheckoutPaymentCoupon } from '.'
import { formatCurrency } from '~/domains/global/libs/formatCurrency'

const { currentPrice, params } = useCheckout()
</script>
