<template>
  <VSelect
    v-model="model"
    label="Parcelas"
    variant="outlined"
    color="primary"
    density="comfortable"
    rounded
    :items="items"
  />
</template>

<script lang="ts" setup>
import { formatCurrency } from '~/domains/global/libs/formatCurrency';

const props = defineProps<{
  maxInstallments: number
  totalPrice: number
}>()

const items = computed(() => {
  const items = []
  for (let i = 1; i <= props.maxInstallments; i++) {
    let price = formatCurrency(props.totalPrice / i)

    if (i === 1) {
      price = formatCurrency(props.totalPrice * 0.95)
    }

    const discountLabel = i === 1 ? '(5% de desconto)' : 'sem juros'

    items.push({
      title: `${i}x de ${price} ${discountLabel}`,
      value: i,
    })
  }
  return items
})

const model = defineModel<number>()
</script>
